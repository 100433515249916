import moment from "moment";
import React from "react";
const DashboardCard = ({ data }) => {
  const getOnSite = () => {
    const startShift = data?.data?.find(
      (item) => item?.name === "Start Shift Time "
    );
    const formattedTime = startShift?.value ? moment(startShift?.value).format("hh:mm A"):"-";
    return (formattedTime === "12:00 AM" ||formattedTime=== "-" ) ? "-" : `${formattedTime} CST`;
  };

  const getShift = () => {
    const startShift = data?.data?.find(
      (item) => item?.name === "Start Shift Time "
    );
    const endShift = data?.data?.find(
      (item) => item?.name === "End Shift Time "
    );
     const formatTime = (value) => {
    if (!value) return "";
    const time = moment(value).format("hh:mm A");
    return time === "12:00 AM" ? "" : time;
  };
    const startTime = formatTime(startShift?.value);
    const endTime = formatTime(endShift?.value);
    return `${startTime} - ${endTime}`;
  };

  return (
    <div className="card dashboardCard">
      <img
        src={data?.createdBy?.imgLink || "https://via.placeholder.com/200"}
        onError={(e) => {
          e.target.onerror = null;
        }}
        className="card-img-top"
        alt="Officer Profile"
      />
      <div className="card-body p-2">
        <h5 className="card-title mb-1 fw-bold text-center">
          {data?.createdBy?.name || "-"}
        </h5>
        <p className="card-text mb-1">{`On Site: ${getOnSite()}`}</p>
        <p className="card-text mb-3">{`Shift: ${getShift()}`}</p>
      </div>
    </div>
  );
};
export default DashboardCard;
